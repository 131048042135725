import styled from 'styled-components'
import device from '../../utils/device-size'

export default styled.div`
  width: 270px;
  background: #1e1e1e;
  .detection-snapshot-container {
    width: 100%;
    height: 206px;
    border-bottom: 1px solid #981214;
    .member-icon {
      width: 72px;
      height: 28px;
    }
  }
  .detection-detail-container {
    width: 100%;
    padding: 16px;
    background: #1e1e1e;
    color: white;
  }
  @media only screen and (max-width: ${device.sizeL}) {
    .detection-snapshot-container {
      height: 143px;
    }
  }
  @media only screen and (max-width: ${device.sizeM}) {
    .detection-snapshot-container {
      height: 166px;
    }
  }
  @media only screen and (max-width: ${device.sizeS}) {
    .detection-snapshot-container {
      height: 134px;
    }
  }
`
