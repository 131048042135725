import React from 'react'
import PropTypes from 'prop-types'

import DetectionSnapshot from '../DetectionSnapshot/dynamic'

import DetectionResultWrapperStyled from './styled'

class DetectionResultWrapper extends React.PureComponent {
  getDetectionSnapShot() {
    return (
      <DetectionSnapshot
        id="img-screenshot-today-visitor"
        className="detection-snapshot-container"
        placeHolderImgSrc={this.props.placeHolderImgSrc}
        imgSrc={this.props.imgSrc}
        isMember={this.props.isMember}
        loading={this.props.loading}
        detectionData={this.props.detectionData}
      />
    )
  }

  render() {
    return (
      <DetectionResultWrapperStyled id={this.props.id} data-test={this.props.id} className={this.props.className}>
        {this.getDetectionSnapShot()}
        <div className="detection-detail-container">{this.props.children}</div>
      </DetectionResultWrapperStyled>
    )
  }
}

DetectionResultWrapper.defaultProps = {
  id: 'detection-result-wrapper',
  isMember: false,
  loading: false,
}

DetectionResultWrapper.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  imgSrc: PropTypes.string,
  placeHolderImgSrc: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element, PropTypes.array]).isRequired,
  isMember: PropTypes.bool,
  loading: PropTypes.bool,
  detectionData: PropTypes.object,
}

export default DetectionResultWrapper
